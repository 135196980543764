import Vue from 'vue';
import Moment from 'moment';


Vue.filter('dateCommonFormat', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY') : '';
});
Vue.filter('dateCommonFormatWithDTime', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY HH:mm') : '';
});
Vue.filter('dateMonthYearFormatter', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD MMM')+ ', ' + Moment(val).format('YYYY') : '';
});
Vue.filter('dateOnlyTime', (val) => {
    return Moment(val).isValid() ? Moment(val).format('HH:mm') : '';
});
Vue.filter('differentDay', function (value, different) {
    let a = Moment(value);
    let b = Moment(different);
    return (a.diff(b, 'days'));
})
Vue.filter('toCurrency', function (value) {
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
});
Vue.filter('jsonFormatText', function (val) {
    if (val) {
        return JSON.stringify(JSON.parse(val), null, 2);
    }
})