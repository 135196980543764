import axios from 'axios'
import TokenService from "../services/TokenService";
import router from '../router/index';
import store from '../store'

export const baseURL = 'https://kelinkerak.uz/';


export const http = axios.create({
    baseURL: baseURL
});


function setConfiguration(provider) {
    provider.interceptors.request.use(config => {
            let token = TokenService.getToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            config.headers['In'] = 'header';
            config.headers['Accept'] = 'application/json';
            config.headers['Access-Control-Allow-Origin'] = '*';
            config.headers['Content-Type'] = 'application/json';
            return config;
        },
        error => Promise.reject(error)
    );
    provider.interceptors.response.use(res => res.data,
        error => {
            if (error.response && (error.response.status === 401)) {
                localStorage.clear();
                router.push({name: 'login'}).then(() => {
                }).catch(e => {
                    if (error.response.status === 401) {
                        this.errorNotification('Token is expired')
                    }
                })
            } else if (error.response && (error.response.status === 401)) {
                router.push({name: '403'}).then();
            }
            return Promise.reject(error)
        });
}

setConfiguration(http)


export default http
