import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import http from "./plugins/axios";
import TokenService from "@/services/TokenService";
import './mixins/index';
import './utils/constants';
import VueApexCharts from 'vue-apexcharts'
import DisableAutocomplete from 'vue-disable-autocomplete';

// styles
import '../public/css/main.scss';
import Moment from "moment";

Vue.prototype.$http = http;
Vue.config.productionTip = false;



Vue.use(VueApexCharts);
Vue.use(DisableAutocomplete);
Vue.component('apexchart', VueApexCharts);


(async function () {
  const token = TokenService.getToken();
  if (token) {
    try {
      await store.dispatch('getPermissions');
      let newDate = Moment(new Date());
      let startedTime = Moment(localStorage.getItem('loginDate'));
    } catch (e) {
      console.log(e)
    }
  }
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})()




