import Vue from 'vue'
import Vuex from 'vuex';


// modules
import user from './modules/user';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: user
  },
  state: {
    loading: false,
    notification: false,
    notificationType: null,
    notificationText: null,
  },
  mutations: {
    changeNotification(state, value) {
      state.notification = value;
    },
    changeNotificationType(state, type) {
      state.notificationType = type;
    },
    changeNotificationText(state, text) {
      state.notificationText = text;
    },
    loading(state, value) {
      state.loading = value;
    },
  },
  actions: {

  },

})
