<template>
  <v-app>
    <!--  Header  -->
    <v-app-bar
        app
        dark
        height="64px"
        class="bg-cerulean"
    >
      <v-app-bar-nav-icon @click="sideBar = !sideBar"></v-app-bar-nav-icon>
      <v-toolbar-title>KK Project Admin Panel</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout()">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--  Header  -->

    <!--  SideBar  -->
    <v-navigation-drawer v-model="sideBar" app>
      <v-list shaped>
        <v-list-item
            to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list v-for="(module, moduleIndex) in modules"
                :key="moduleIndex"
        >
          <v-subheader :class="module.bgColor"
                       v-text="module.title"
                       class="text-uppercase text-subtitle-1 white--text rounded-r-xl"></v-subheader>
          <v-list-group
              v-for="(menu, menuIndex) in module.menus"
              :key="menuIndex"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                    v-text="menu.name"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="child in menu.items"
                :key="child.title"
                :to="child.link"
                class="pl-13"
            >
              <v-list-item-content>
                <v-list-item-title

                    v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>


      </v-list>
    </v-navigation-drawer>
    <!--  SideBar  -->

    <v-main>
      <v-container fluid class="h-100">
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import {PERMISSIONS} from "@/utils/constants";
import TokenService from "../services/TokenService";

export default {
  name: "MainLayout",
  data: () => ({
    sideBar: true,
    modules: [
      {
        title: 'Kelin Kerak',
        bgColor: 'green accent-4',
        menus: [
          {
            name: 'Локализация',
            permission: PERMISSIONS['LOCALIZATIONS.CONTROLLER'],
            items: [
              {
                title: 'Все локализации',
                link: '/localization-all',
                permission: PERMISSIONS['LOCALIZATIONS.CONTROLLER'],
              },
              {
                title: 'Группы локализации',
                link: '/localization-group',
                permission: PERMISSIONS['LOCALIZATIONS.GROUP.CONTROLLER'],
              },
            ]
          },
          {
            name: 'Разрешения',
            permission: PERMISSIONS['PERMISSIONS.CONTROLLER'],
            items: [
              {
                title: 'Разрешения',
                link: '/permissions',
                permission: PERMISSIONS['PERMISSIONS.CONTROLLER'],
              },
            ]
          },
          {
            name: 'Вопросы',
            permission: PERMISSIONS['QUESTIONS.CONTROLLER'],
            items: [
              {
                title: 'Вопросы',
                link: '/questions',
                permission: PERMISSIONS['QUESTIONS.CONTROLLER'],
              },
            ]
          },
          {
            name: 'System Variable',
            permission: PERMISSIONS['SYSTEMVARIABLE.CONTROLLER'],
            items: [
              {
                title: 'System Variable',
                link: '/system-variable',
                permission: PERMISSIONS['SYSTEMVARIABLE.CONTROLLER'],
              },
            ]
          },
          {
            name: 'Roles',
            permission: PERMISSIONS['ROLES.CONTROLLER'],
            items: [
              {
                title: 'Roles',
                link: '/roles',
                permission: PERMISSIONS['ROLES.CONTROLLER'],
              },
            ]
          },
          {
            name: 'Region',
            permission: PERMISSIONS['COUNTRIES.CONTROLLER'] || PERMISSIONS['COUNTRIES.CONTROLLER'] || PERMISSIONS['COUNTRIES.CONTROLLER'],
            items: [
              {
                title: 'Countries',
                link: '/countries',
                permission: PERMISSIONS['COUNTRIES.CONTROLLER'],
              },
              {
                title: 'Districts',
                link: '/districts',
                permission: PERMISSIONS['DISTRICTS.CONTROLLER'],
              },
              {
                title: 'City',
                link: '/city',
                permission: PERMISSIONS['CITY.CONTROLLER'],
              },
            ]
          },
          {
            name: 'Users',
            permission: PERMISSIONS['USERS.CONTROLLER'],
            items: [
              {
                title: 'Users',
                link: '/users',
                permission: PERMISSIONS['USERS.CONTROLLER'],
              },
            ]
          },
          {
            name: 'Отзывы',
            permission: PERMISSIONS['FEEDBACK.CONTROLLER'],
            items: [
              {
                title: 'Отзывы',
                link: '/feedback',
                permission: PERMISSIONS['FEEDBACK.CONTROLLER'],
              },
            ]
          },
          {
            name: 'People',
            items: [
              {
                title: 'Brides',
                link: '/brides',
                permission: PERMISSIONS['BRIDE.CONTROLLER'],
              },
              {
                title: 'Grooms',
                link: '/grooms',
                permission: PERMISSIONS['GROOM.CONTROLLER'],
              },
            ]
          },
        ]
      },
    ],
  }),
  methods: {
    async logout() {
      try {
          const res  = await this.$http.get(`/api-authUser/logout?token=${TokenService.getRefreshToken()}`);
          if (res) {
            localStorage.clear();
            await this.$router.push({path: '/login'})
          }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    checkMenu(permission){
      return this.$store.state.user.permissions.find(item => item === permission);
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>