import {PERMISSIONS} from "@/utils/constants";

export default {
    methods: {
        showLoader() {
            this.$store.commit('loading', true);
        },
        hideLoader() {
            this.$store.commit('loading', false);
        },
        getNameById(list = [], id,  keyName = 'name') {
            if (list.length > 0) {
                let item = list.find(x => x.id === id);
                return item[keyName];
            }
        }
    },
    computed: {
        $permissions() {
            return PERMISSIONS
        }
    }
}