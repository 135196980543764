import store from '../store/index'


const can = function (value) {
    if(value ){
        return !!store.state.user.permissions.find(item => item === value);
    }
};

export default can;
