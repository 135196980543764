<template>
  <v-app>
    <router-view></router-view>
    <v-snackbar
        v-if="$store.state.notification && $store.state.notificationType === 'success'"
        :timeout="-1"
        :value="true"
        absolute
        :color="`${['green accent-3']}`"
        right
        top
        class="notification-snackbar"
        max-width="300px"
    >
      {{ $store.state.notificationText }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="hideNotification" small>mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
        v-if="$store.state.notification && $store.state.notificationType === 'error'"
        :timeout="-1"
        :value="true"
        absolute
        :color="`${['red darken-2']}`"
        right
        top
        class="notification-snackbar"
        max-width="300px"
    >
      {{ $store.state.notificationText }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="hideNotification" small>mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
        v-if="$store.state.notification && $store.state.notificationType === 'info'"
        :timeout="-1"
        :value="true"
        absolute
        color="deep-purple accent-4"
        right
        top
        class="notification-snackbar"
        max-width="300px"
    >
      {{ $store.state.notificationText }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="hideNotification" small>mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-overlay :value="$store.state.loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>

</template>

<script>

export default {
  name: 'App',
  components: {},


};
</script>
