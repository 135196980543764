import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from "@/layouts/MainLayout";
import can from "../mixins/can";
import {PERMISSIONS} from "../utils/constants";
import TokenService from "../services/TokenService";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Auth/Index')
    },
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@/views/MainLayoutView/Dashboard/Dashboard')
            },
            {
                path: '/403',
                name: '403',
                component: () => import('@/views/MainLayoutView/CommonPages/403')
            },
            {
                path: '/localization-all',
                name: 'localization-all',
                component: () => import('@/views/MainLayoutView/Localizations/LocalizationAll'),
                meta: {
                    permission: PERMISSIONS['LOCALIZATIONS.CONTROLLER'],
                }
            },
            {
                path: '/localization-group',
                name: 'localization-group',
                component: () => import('@/views/MainLayoutView/Localizations/LocalizationGroup'),
                meta: {
                    permission: PERMISSIONS['LOCALIZATIONS.GROUP.CONTROLLER'],
                }
            },
            {
                path: '/permissions',
                name: 'permissions',
                component: () => import('@/views/MainLayoutView/Permissions/Permissions'),
                meta: {
                    permission: PERMISSIONS['PERMISSIONS.CONTROLLER'],
                }
            },
            {
                path: '/questions',
                name: 'questions',
                component: () => import('@/views/MainLayoutView/Questions/Questions'),
                meta: {
                    permission: PERMISSIONS['PERMISSIONS.CONTROLLER'],
                }
            },
            {
                path: '/system-variable',
                name: 'system-variable',
                component: () => import('@/views/MainLayoutView/SystemVariable/SystemVariable'),
                meta: {
                    permission: PERMISSIONS['SYSTEMVARIABLE.CONTROLLER'],
                }
            },
            {
                path: '/roles',
                name: 'roles',
                component: () => import('@/views/MainLayoutView/Roles/Roles'),
                meta: {
                    permission: PERMISSIONS['ROLES.CONTROLLER'],
                }
            },
            {
                path: '/countries',
                name: 'countries',
                component: () => import('@/views/MainLayoutView/Countries/Countries'),
                meta: {
                    permission: PERMISSIONS['COUNTRIES.CONTROLLER'],
                }
            },
            {
                path: '/districts',
                name: 'districts',
                component: () => import('@/views/MainLayoutView/Districts/Districts'),
                meta: {
                    permission: PERMISSIONS['COUNTRIES.CONTROLLER'],
                }
            },
            {
                path: '/city',
                name: 'city',
                component: () => import('@/views/MainLayoutView/City/City'),
                meta: {
                    permission: PERMISSIONS['CITY.CONTROLLER'],
                }
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/MainLayoutView/Users/Users'),
                meta: {
                    permission: PERMISSIONS['USERS.CONTROLLER'],
                }
            },
            {
                path: '/feedback',
                name: 'feedback',
                component: () => import('@/views/MainLayoutView/Feedback/Feedback'),
                meta: {
                    permission: PERMISSIONS['FEEDBACK.CONTROLLER'],
                }
            },
            {
                path: '/brides',
                name: 'brides',
                component: () => import('@/views/MainLayoutView/People/Brides'),
                meta: {
                    permission: PERMISSIONS['BRIDE.CONTROLLER'],
                }
            },
            {
                path: '/grooms',
                name: 'grooms',
                component: () => import('@/views/MainLayoutView/People/Grooms'),
                meta: {
                    permission: PERMISSIONS['GROOM.CONTROLLER'],
                }
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    const isAuthenticated = !!TokenService.getToken()
    if (to.name !== 'login' && !isAuthenticated) {
        return next({name: 'login'})
    } else {
        if (to.meta.permission) {
            if (!can(to.meta.permission)) {
                next({path: '/403'})
            }
            next()
        } else {
            next()
        }
    }
})


export default router
