import http from "../../plugins/axios";


export default {
    state: {
        permissions: [],
        languages: [
            {
                value: 'RU',
                id: 'ru'
            },
            {
                value: 'EN',
                id: 'en'
            },
            {
                value: 'UZ',
                id: 'uz'
            },
        ]
    },
    getters: {
        languages: state => state.languages
    },
    actions: {
        async getPermissions(context) {
            try {
                const res = await http.get('/api-authUser/getUserPermissions');
                context.commit('SET_PERMISSION', res.result)
            } catch (e) {
                this.errorNotification(e.response.data.message)
            }
        },
    },
    mutations: {
        SET_PERMISSION(state, data) {
            state.permissions = data
        },
    }
}