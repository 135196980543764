export const PERMISSIONS = {
    "PERMISSIONS.CONTROLLER" : 'authpermission_getpermissions',
    "PERMISSION.UPSERT": 'authpermission_upsertpermission',
    "PERMISSION.TOGGLE": 'authpermission_toggleactivation',

    "LOCALIZATIONS.CONTROLLER": 'localization_getgroupitems',
    "LOCALIZATION.UPSERT": 'localization_upsertgroupitem',
    "LOCALIZATION.TOGGLE": 'localization_togglegroupitemactivation',
    "LOCALIZATION.MOVEUP": 'localization_movegroupitemup',
    "LOCALIZATION.MOVEDOWN": 'localization_movegroupitemdown',

    "LOCALIZATIONS.GROUP.CONTROLLER": 'localization_getgroups',
    "LOCALIZATION.GROUP.UPSERT": 'localization_upsertgroup',
    "LOCALIZATION.GROUP.TOGGLE": 'localization_togglegroupactivation',

    "QUESTIONS.CONTROLLER": 'question_getquestions',
    "QUESTIONS.UPSERT": 'localization_upsertgroup',
    "QUESTIONS.TOGGLE": 'localization_togglegroupactivation',
    "QUESTIONS.MOVEUP": 'localization_movegroupitemup',
    "QUESTIONS.MOVEDOWN": 'localization_movegroupitemdown',

    "SYSTEMVARIABLE.CONTROLLER": 'variable_getvariables',
    "SYSTEMVARIABLE.UPSERT": 'variable_upsertvariable',
    "SYSTEMVARIABLE.TOGGLE": 'variable_toggleactivation',

    "ROLES.CONTROLLER": 'authrole_getroles',
    "ROLES.UPSERT": 'authrole_upsertrole',
    "ROLES.TOGGLE": 'authrole_toggleactivation',
    "ROLES.SETPERMISSIONS": 'authrole_setpermissions',

    "COUNTRIES.CONTROLLER": 'region_getcountries',
    "COUNTRIES.UPSERT": 'region_upsertcountry',
    "COUNTRIES.TOGGLE": 'region_togglecountry',
    "COUNTRIES.MOVEUP": 'region_movecountryup',
    "COUNTRIES.MOVEDOWN": 'region_movecountrydown',

    "DISTRICTS.CONTROLLER": 'region_getdistricts',
    "DISTRICTS.UPSERT": 'region_upsertdistrict',
    "DISTRICTS.TOGGLE": 'region_toggledistrict',
    "DISTRICTS.MOVEUP": 'region_movedistrictup',
    "DISTRICTS.MOVEDOWN": 'region_movedistrictdown',

    "CITY.CONTROLLER": 'region_getdistricts',
    "CITY.UPSERT": 'region_upsertdistrict',
    "CITY.TOGGLE": 'region_toggledistrict',
    "CITY.MOVEUP": 'region_movedistrictup',
    "CITY.MOVEDOWN": 'region_movedistrictdown',

    "USERS.CONTROLLER": 'menu_user',
    "USERS.UPSERT": 'authuser_updateuser',

    "FEEDBACK.CONTROLLER": 'feedback_getfeedbacks',
    "FEEDBACK.UPSERT": 'feedback_updatefeedback',
    "FEEDBACK.TOGGLE": 'feedback_toggleactivation',
    "FEEDBACK.REMOVE": 'feedback_removefeedback',
    "FEEDBACK.APPROVE": 'feedback_toggleapprove',

    "BRIDE.CONTROLLER": 'people_getadminbrides',
    "BRIDE.UPSERT": 'people_upsertbride',
    "BRIDE.GETINFO": 'people_getadminbrideinfo',
    "BRIDE.APPROVE": 'people_approvebride',
    "BRIDE.DECLINE": 'people_declinebride',
    "BRIDE.DELETE": 'people_togglebrideactivation',

    "GROOM.CONTROLLER": 'people_getadmingrooms',
    "GROOM.UPSERT": 'people_upsertgroom',
    "GROOM.GETINFO": 'people_getadmingroominfo',
    "GROOM.APPROVE": 'people_approvegroom',
    "GROOM.DECLINE": 'people_declinegroom',
    "GROOM.DELETE": 'people_togglegroomactivation',

}